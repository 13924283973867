/* fonts.css */


body {
  margin: 0;
  font-family: 'Chalkboard', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #5175A9;
}


