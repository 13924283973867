@font-face {
    font-family: 'CustomFont';
    src:
         url('../fonts/Chalk\ Board.otf') format('woff'),
         url('../fonts/Chalk\ Board.ttf') format('truetype');
  
  }
  
  body {
    font-family: 'CustomFont', sans-serif;
    /* overflow-x: hidden;  */
  }
  
.App {
    display: flex;
    justify-content: center;
    height: 100vh;
    text-align: center;
   
}

.blackboard-container {
    margin-bottom: 10px; 
    padding-bottom: 0; 
    margin-top: 50px;
    position: relative;
    width: 50%;
    height: 65%;
    justify-content: center;
    border-radius: 10px;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    border: 25px solid #B08050;
    background-color: #69A460;
    color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    text-align: center; 
}

.blackboard-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    pointer-events: none;
}

.heading {
    font-size: 2.5em;
    margin-bottom: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); 
    font-weight: bold;
}

.text2 {
    margin-top: 20px;
    font-size: 24px;
    width: 100%;
    color: white;
}

.text3 {
    margin-top: 10px;
    font-size: 16px;
    width: 100%;
    margin-bottom: 5px;
    line-height: 1.5;
    color: white;
}

.links {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.links a {
    background-color: white;
    border: 2px solid #E5E5E5;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    cursor: pointer; 
    transition: background-color 0.3s ease; 
    margin : 5px; /* Adjust margin as necessary */
}

.links a:hover {
    background-color:  rgba(255, 255, 255, 0.9);
}

.links img {
    width: 30px;
    height: auto;

}

.logo {
    position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 40%;
  object-fit: cover;
  z-index: 1; 

   }   
   .scrolled {
  top:150px;
    bottom: auto; 

}

.thebutton {
    margin-top: 20px;
    margin-left: 10px;
    padding: 15px 60px;
    border: 2px solid black;
    border-radius: 40px;
    background-color:#EA5E2A;
    font-size: 16px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.9);
    transition: box-shadow 0.2s ease, transform 0.2s ease, background-color 0.2s ease;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.5);
    width: auto; 

}

.thebutton:hover {
    box-shadow: 0 -4px 0 rgba(0, 0, 0, 0.5);
    background-color: #EA5E2A;
}
.thebutton-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 4px;
    font-size: 10px;
    position: absolute;
    z-index: 2;
    bottom: 85%; /* Position tooltip above button */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  .tooltip::after {
    content: "";
    position: absolute;
    top: 100%; 
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .thebutton-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  


@media (max-width: 768px) {
  

    .blackboard-container {
        padding: 0px;
        border: none;
        margin-top: 5px;
        background-color: transparent; 
        box-shadow: none; 
        width: auto;
        max-width: none;
        text-align: center;
        position: relative;
        margin-bottom: 5px; 
    }

    .blackboard-container,
    .blackboard-container::before {
        border: none;
        background-color: transparent;
        box-shadow: none;
    }

    .links {
        margin-top: 30px;
        width: 85%;
        display: flex;
        justify-content: center;
        margin-left: 20px;
    }
 


    .links a:hover {
        background-color:rgba(255, 255, 255, 0.9) ; 
    }

    .links img {
        max-width: 70%; 
        max-height: 70%;
        width: auto; 
        height: auto; 
    }


    .heading {
        margin-top: 50px;
        font-size: 2.5em;
        margin-bottom: 5px;
        text-align: center;
    }

    .text2 {
        font-size: 26px;
        margin-bottom: 10px;
        text-align: center; 
        color: white; 
    }

    .text3 {
        font-size: 18px;
        position: relative;
        z-index: 2; 
        margin-bottom: 1px; 
    }

    .button-text-container {
        display: flex;
        flex-direction: column-reverse; /* Stack text below button on small devices */
        align-items: center; 
    }

    .thebutton {
     font-size: 21px;
   
        background-color: #d59f21;
        margin-top: 280px;
        margin-bottom: 0px;
        display: block;
        position: relative;
        z-index: 2;
        white-space: nowrap; /* Ensure button text does not wrap */
    }
    .thebutton:hover{
        background-color: #d59f21;
    }
 
      .tooltip {
        bottom: 20%; 
        font-size: 9px; 
        padding: 4px; 
      
      }
      .logo {
        position: fixed;
        bottom: 0;
        left: -75px;
        width: 380px;
        height: 300px; 
        z-index: 1;
       
    }
    
 
    
    

}
